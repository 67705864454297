import * as React from "react";
import { FunctionComponent } from "react";
import { Heading, Page } from "../components";

const RulesAndRegulation: FunctionComponent = (): JSX.Element => (
  <Page layout="default" title="Electronic Communications Consent and Notice">
    <div className="tile--two-thirds">
      <Heading
        className="text-uppercase"
        level={1}
        style={{ textAlign: "center" }}
      >
        {`Consent and notice regarding electronic communications`}
      </Heading>
      <div className="tile info-section">
        <p>
          <strong className="text-uppercase">
            {`Consent and notice regarding electronic communications, use of surepay
          form and change of address form on hawaiiana management company,
          ltd.’s website:`}
          </strong>
        </p>
        <p>
          <strong>Electronic Signature Agreement. </strong>
          {`By selecting the "I Accept" button,
          you are signing this Surepay Form and/or Change of Address Form
          (collectively and separately referred to as “FORMS”) electronically.
          You agree your electronic signature is the legal equivalent of your
          manual signature on the FORMS. By selecting "I Accept" you consent to
          be legally bound by the FORMS’S terms and conditions. You further
          agree that your use of a key pad, mouse or other device to select an
          item, button, icon or similar act/action, or to otherwise provide
          Hawaiiana Management Company, Ltd. as Agent for your Association
          instructions or in accessing or making any transaction regarding any
          agreement, acknowledgement, consent terms, disclosures or conditions
          constitutes your signature (hereafter referred to as "E-Signature"),
          acceptance and agreement as if actually signed by you in writing. You
          also agree that no certification authority or other third party
          verification is necessary to validate your E-Signature and that the
          lack of such certification or third party verification will not in any
          way affect the enforceability of your E-Signature. You also represent
          that you are authorized to submit the FORMS for all persons who are
          owners of apartment or unit or lot that is part of the Association and
          that such persons will be bound by the terms of the FORMS.`}
        </p>
        <p>
          <strong>Valid and current email address. </strong>
          {`Your current valid email address is
          required in order for you to use online the FORMS. You agree to keep
          Hawaiiana Management Company, Ltd. as agent for your Association
          informed of any changes in your email address. You may modify your
          email address by submitting a written request to Hawaiiana Management
          Company, Ltd. as Agent for your Association.`}
        </p>
        <p>
          <strong>Hardware, software and operating system. </strong>
          {`You are responsible for
          installation, maintenance, and operation of your computer, browser and
          software. Hawaiiana Management Company, Ltd. as Agent for your
          Association is not responsible for errors or failures from any
          malfunction of your computer, browser or software. Hawaiiana
          Management Company, Ltd. as Agent for your Association is not
          responsible for computer viruses or related problems associated with
          use of an online system.`}
        </p>
      </div>
    </div>
  </Page>
);

export default RulesAndRegulation;
